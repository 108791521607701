import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { FileInput } from "@/components/Inputs/FileInput"
import { Input } from "@/components/Inputs/Input"
import { Spoiler } from "@/components/Layouts/Spoiler"
import { Form, SubmitType } from "@/features/Form/Form"
import { ResourcePermissions } from "@/models/Permission"
import axios from "axios"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
    clearErrors,
    createFolder,
    createMediaInFolder,
    createRootFolder,
    getPreSignedUrlForUploadInFileManager,
    getResourcePermissions,
} from "../fileManagerSlice"
import { FileManagerPermissionInputs } from "./FileManagerPermissionInputs"

interface FileManagerCreateFormProps {
    resourceType: "folder" | "file"
    parentFolderId?: string | null
    closeModal?: () => void
    handleSubmit?: (permissions: ResourcePermissions) => void
    files?: File | File[] | null
    setFile?: (file: File | File[] | null) => void
    multiple?: boolean
}

export const FileManagerCreateForm: React.FC<FileManagerCreateFormProps> = ({
    resourceType,
    parentFolderId,
    closeModal,
    handleSubmit,
    files,
    setFile,
    multiple,
}) => {
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? ""
    const status = useAppSelector((state) => state.fileManager.oneStatus)
    const [isLoading, setIsLoading] = useState<SliceStatus>(SliceStatus.IDLE)
    const [progress, setProgress] = useState<number>(0)
    const ifcViewerStatus = useAppSelector((state) => state.ifcViewer.status)
    const ifcUploadProgress = useAppSelector(
        (state) => state.ifcViewer.ifcUploadProgress,
    )

    const resourcePermissions = useAppSelector(
        (state) => state.fileManager.resourcePermission,
    )
    const [resourceName, setResourceName] = useState<string>("")
    const [localResourcePermissions, setLocalResourcePermissions] =
        useState<ResourcePermissions>(resourcePermissions)
    const [localFile, setLocalFile] = useState<File | File[] | null>(null)
    const [isGlobal, setIsGlobal] = useState<boolean>(false)

    const handleCreateFolder = () => {
        if (parentFolderId && parentFolderId !== "-1") {
            const payload = {
                name: resourceName,
                projectId,
                permissions: localResourcePermissions,
                parentFolderId,
                isGlobal,
            }
            dispatch(createFolder(payload)).then(() => {
                closeModal && closeModal()
            })
        } else {
            const payload = {
                name: resourceName,
                projectId,
                permissions: localResourcePermissions,
                isGlobal,
            }
            dispatch(createRootFolder(payload)).then(() => {
                closeModal && closeModal()
            })
        }
    }

    const handleUploadFile = async () => {
        setIsLoading(SliceStatus.LOADING)
        const files = Array.isArray(localFile) ? localFile : [localFile]
        const filteredFiles = files.filter((file) => file !== null)
        await Promise.all(
            filteredFiles.map(async (file) => {
                if (!file) return
                const res = await dispatch(
                    getPreSignedUrlForUploadInFileManager({
                        projectId,
                        folderId: parentFolderId ?? "-1",
                        fileName: file.name,
                        fileType: file.type,
                    }),
                )
                const { data: preSignedUrl } = res.payload.data
                await axios.put(preSignedUrl, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total,
                            )
                            setProgress(progress)
                        }
                    },
                })
            }),
        )
        await dispatch(
            createMediaInFolder({
                projectId,
                folderId: parentFolderId ?? "-1",
                files: filteredFiles
                    .filter((file) => file !== null)
                    .map((file) => ({
                        fileName: file && file.name,
                        fileType: file && file.type,
                        size: file && file.size,
                    })),
                permissions: localResourcePermissions,
                isGlobal,
            }),
        )
        if (closeModal) {
            closeModal()
        }
        setIsLoading(SliceStatus.IDLE)
        // const formData = new FormData()
        // if (Array.isArray(localFile)) {
        //     localFile.forEach((file) => {
        //         formData.append("files", file)
        //     })
        // } else {
        //     formData.append("files", localFile as File)
        // }
        // formData.append("permissions", JSON.stringify(localResourcePermissions))
        // formData.append("isGlobal", isGlobal.toString())
        // dispatch(
        //     uploadFileToFolder({
        //         projectId,
        //         folderId: parentFolderId ?? "-1",
        //         payload: formData,
        //     }),
        // ).then(() => {
        //     closeModal && closeModal()
        // })
    }

    useEffect(() => {
        if (parentFolderId !== "-1") {
            dispatch(
                getResourcePermissions({
                    projectId,
                    resourceId: parentFolderId,
                    resourceType: "folder",
                }),
            )
        }
    }, [])
    useEffect(() => {
        setLocalResourcePermissions(resourcePermissions)
    }, [resourcePermissions])
    return (
        <Form
            statuses={[status.create, ifcViewerStatus.create, isLoading]}
            loadingTitle={
                resourceType === "file"
                    ? `${t("uploading_file")} ${ifcViewerStatus.create === SliceStatus.LOADING ? ifcUploadProgress + "%" : ""} ${isLoading === SliceStatus.LOADING ? progress + "%" : ""}`
                    : t("creating_folder")
            }
            onSubmit={() => {
                if (resourceType === "folder") {
                    handleCreateFolder()
                } else {
                    if (handleSubmit) {
                        handleSubmit(localResourcePermissions)
                    } else {
                        handleUploadFile()
                    }
                }
            }}
            onCancel={closeModal}
            submitType={SubmitType.Create}
            clearErrors={clearErrors}
        >
            <div className="w-full">
                <h1 className="text-xl font-bold">
                    {resourceType === "folder"
                        ? t("create_folder")
                        : t("upload_file")}
                    :
                </h1>
                {resourceType === "folder" ? (
                    <Input
                        name="name"
                        label={t("name")}
                        type="text"
                        value={resourceName}
                        onChange={(e) => {
                            setResourceName(e.target.value)
                        }}
                        className="mb-4"
                    />
                ) : (
                    <div className="my-4">
                        <FileInput
                            label={t("files")}
                            name={t("files_to_upload")}
                            value={files ?? localFile}
                            setState={setFile ?? setLocalFile}
                            multiple={multiple}
                        />
                    </div>
                )}
                <div className="mb-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={isGlobal}
                            onChange={(e) => setIsGlobal(e.target.checked)}
                            className="mr-2"
                        />
                        {t("set_resource_as_global", {
                            resource: t(resourceType),
                        })}
                    </label>
                </div>
                <Spoiler title={t("permissions")} icon="user-lock">
                    <FileManagerPermissionInputs
                        value={localResourcePermissions}
                        setValue={setLocalResourcePermissions}
                    />
                </Spoiler>
            </div>
        </Form>
    )
}
