import { createAsyncThunkWithNotification } from "@/app/common"
import { RootState } from "@/app/store"
import { DEFAULT_REDUCER_STATUS } from "@/common/consts"
import { FormErrors, ReducerStatus, SliceStatus } from "@/common/types"
import { FileJsonInterface, ProjectFile } from "@/models/File"
import {
    IfcEntity,
    IfcEntityInterface,
    IfcModel,
    IfcModelInterface,
} from "@/models/Ifc"
import { ResourcePermissions } from "@/models/Permission"
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { FragmentIdMap } from "@thatopen/fragments"
import {
    addFileToIfcModelApi,
    attachEntitiesToTaskApi,
    createIfcModelApi,
    deleteIfcModelApi,
    getFileSignedUrlsApi,
    getIfcFileCompletedEntitiesApi,
    getIfcFileEntitiesApi,
    getIfcFileInProgressEntitiesApi,
    getIfcFileNotStartedEntitiesApi,
    getIfcModelFilesApi,
    getProjectIfcFilesApi,
    getProjectIfcModelsApi,
    setIfcEntitiesArticleApi,
    updateIfcEntitiesProgressApi,
} from "./ifcApi"

interface FloorPlan {
    id: string
    name: string
}

export interface FragmentIdMapNoSet {
    [key: string]: number[]
}

export interface Model {
    id: string
    name: string
    path: string
}

export const getProjectIfcFiles = createAsyncThunkWithNotification(
    "ifcViewer/getProjectIfcFiles",
    async (projectId: string) => {
        const response = await getProjectIfcFilesApi(projectId)
        return response
    },
)

export const createIfcModel = createAsyncThunkWithNotification(
    "ifcViewer/createIfcModel",
    async ({
        projectId,
        name,
        description,
        permissions,
    }: {
        projectId: string
        name: string
        description: string
        permissions: ResourcePermissions
    }) => {
        const response = await createIfcModelApi(projectId, {
            name,
            description,
            permissions,
        })
        return response
    },
)

export const deleteIfcModel = createAsyncThunkWithNotification(
    "ifcViewer/deleteIfcModel",
    async ({
        projectId,
        ifcModelId,
    }: {
        projectId: string
        ifcModelId: string
    }) => {
        const response = await deleteIfcModelApi(projectId, ifcModelId)
        return response
    },
)

export const getProjectIfcModels = createAsyncThunkWithNotification(
    "ifcViewer/getProjectIfcModels",
    async (projectId: string) => {
        const response = await getProjectIfcModelsApi(projectId)
        return response
    },
)

export const addFileToIfcModel = createAsyncThunkWithNotification(
    "ifcViewer/addFileToIfcModel",
    async ({
        projectId,
        ifcModelId,
        file,
    }: {
        projectId: string
        ifcModelId: string
        file: {
            fileName: string
            fileType: string
            size: number
        }
    }) => {
        const response = await addFileToIfcModelApi(projectId, ifcModelId, file)
        return response
    },
)

export const getFileSignedUrls = createAsyncThunkWithNotification(
    "ifcViewer/getFileSignedUrlsApi",
    async ({
        projectId,
        ifcModelId,
        fileId,
    }: {
        projectId: string
        ifcModelId: string
        fileId: string
    }) => {
        const response = await getFileSignedUrlsApi(
            projectId,
            ifcModelId,
            fileId,
        )
        return response
    },
)

export const getIfcModelFiles = createAsyncThunkWithNotification(
    "ifcViewer/getIfcModelFiles",
    async ({
        projectId,
        ifcModelId,
    }: {
        projectId: string
        ifcModelId: string
    }) => {
        const response = await getIfcModelFilesApi(projectId, ifcModelId)
        return response
    },
)

export const attachEntitiesToTask = createAsyncThunkWithNotification(
    "ifcViewer/attachEntitiesToTask",
    async ({
        projectId,
        taskId,
        ifcEntitiesIds,
    }: {
        projectId: string
        taskId: string
        ifcEntitiesIds: string[]
    }) => {
        const response = await attachEntitiesToTaskApi(
            projectId,
            taskId,
            ifcEntitiesIds,
        )
        return response
    },
)

export const updateIfcEntitiesProgress = createAsyncThunkWithNotification(
    "ifcViewer/updateIfcEntitiesProgress",
    async ({
        projectId,
        entities,
    }: {
        projectId: string
        entities: Record<string, number>
    }) => {
        const response = await updateIfcEntitiesProgressApi(projectId, entities)
        return response
    },
)

export const getIfcFileEntities = createAsyncThunkWithNotification(
    "ifcViewer/getIfcFileEntities",
    async ({
        projectId,
        ifcFileId,
    }: {
        projectId: string
        ifcFileId: string
    }) => {
        const response = await getIfcFileEntitiesApi(projectId, ifcFileId)
        return response
    },
)

export const setIfcEntitiesArticle = createAsyncThunkWithNotification(
    "ifcViewer/setIfcEntitiesArticle",
    async ({
        projectId,
        articleId,
        entities,
    }: {
        projectId: string
        articleId: string
        entities: string[]
    }) => {
        const response = await setIfcEntitiesArticleApi(
            projectId,
            articleId,
            entities,
        )
        return response
    },
)

export const getIfcFileNotStartedEntities = createAsyncThunkWithNotification(
    "ifcViewer/getIfcFileNotStartedEntities",
    async ({
        projectId,
        ifcFileId,
    }: {
        projectId: string
        ifcFileId: string
    }) => {
        const response = await getIfcFileNotStartedEntitiesApi(
            projectId,
            ifcFileId,
        )
        return response
    },
)
export const getIfcFileInProgressEntities = createAsyncThunkWithNotification(
    "ifcViewer/getIfcFileInProgressEntities",
    async ({
        projectId,
        ifcFileId,
    }: {
        projectId: string
        ifcFileId: string
    }) => {
        const response = await getIfcFileInProgressEntitiesApi(
            projectId,
            ifcFileId,
        )
        return response
    },
)
export const getIfcFileCompletedEntities = createAsyncThunkWithNotification(
    "ifcViewer/getIfcFileCompletedEntities",
    async ({
        projectId,
        ifcFileId,
    }: {
        projectId: string
        ifcFileId: string
    }) => {
        const response = await getIfcFileCompletedEntitiesApi(
            projectId,
            ifcFileId,
        )
        return response
    },
)

export interface IfcViewerState {
    ifcUploadProgress: number
    ifcFiles: FileJsonInterface[]
    selection: FragmentIdMapNoSet
    status: ReducerStatus
    newIfcModels: IfcModelInterface[]
    newIfcEntities: IfcEntityInterface[]
    ifcEntities: IfcEntityInterface[]
    ifcModelFiles: FileJsonInterface[]
    selectedEntities: IfcEntityInterface[]
    isLoading: boolean
    errors: FormErrors
}

const initialState: IfcViewerState = {
    ifcUploadProgress: 0,
    ifcFiles: [],
    selection: {},
    status: DEFAULT_REDUCER_STATUS,
    newIfcModels: [],
    newIfcEntities: [],
    ifcEntities: [],
    ifcModelFiles: [],
    selectedEntities: [],
    isLoading: false,
    errors: {},
}

export const ifcViewerSlice = createSlice({
    name: "ifcViewer",
    initialState,
    reducers: {
        setSelection: (state, action) => {
            state.selection = action.payload
        },
        setIfcUploadProgress: (state, action) => {
            state.ifcUploadProgress = action.payload
        },
        clearErrors: (state) => {
            state.errors = {}
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createIfcModel.pending, (state) => {
            state.status.create = SliceStatus.LOADING
        })
        builder.addCase(createIfcModel.fulfilled, (state, action) => {
            state.status.create = SliceStatus.IDLE
            state.newIfcModels = [
                ...state.newIfcModels,
                action.payload.data.data,
            ]
        })
        builder.addCase(createIfcModel.rejected, (state, action) => {
            state.status.create = SliceStatus.FAILED
            state.newIfcModels = []
            state.errors = (action.payload as any).data
        })
        builder.addCase(deleteIfcModel.pending, (state) => {
            state.status.delete = SliceStatus.LOADING
        })
        builder.addCase(deleteIfcModel.fulfilled, (state, action) => {
            state.status.delete = SliceStatus.IDLE
            state.newIfcModels = state.newIfcModels.filter(
                (model) => model.id !== action.payload.data.data,
            )
        })
        builder.addCase(deleteIfcModel.rejected, (state) => {
            state.status.delete = SliceStatus.FAILED
        })
        builder.addCase(getProjectIfcModels.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getProjectIfcModels.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.newIfcModels = action.payload.data.data
        })
        builder.addCase(getProjectIfcModels.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.newIfcModels = []
        })
        builder.addCase(addFileToIfcModel.pending, (state) => {
            state.status.create = SliceStatus.LOADING
        })
        builder.addCase(addFileToIfcModel.fulfilled, (state, action) => {
            state.status.create = SliceStatus.IDLE
            state.newIfcModels = state.newIfcModels.map((model) => {
                if (model.id === action.payload.data.data.id) {
                    return action.payload.data.data
                }
                return model
            })
        })
        builder.addCase(addFileToIfcModel.rejected, (state) => {
            state.status.create = SliceStatus.FAILED
        })
        builder.addCase(attachEntitiesToTask.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(attachEntitiesToTask.fulfilled, (state) => {
            state.status.update = SliceStatus.IDLE
        })
        builder.addCase(attachEntitiesToTask.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(getIfcFileEntities.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getIfcFileEntities.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.ifcEntities = action.payload.data.data
        })
        builder.addCase(getIfcFileEntities.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.ifcEntities = []
        })
        builder.addCase(setIfcEntitiesArticle.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(setIfcEntitiesArticle.fulfilled, (state, action) => {
            state.status.update = SliceStatus.IDLE
            state.ifcEntities = state.ifcEntities.map((entity) => {
                const newEntity = action.payload.data.data.find(
                    (updatedEntity: any) => updatedEntity.id === entity.id,
                )
                if (newEntity) {
                    return newEntity
                }
                return entity
            })
        })
        builder.addCase(setIfcEntitiesArticle.rejected, (state) => {
            state.status.update = SliceStatus.FAILED
        })
        builder.addCase(updateIfcEntitiesProgress.pending, (state) => {
            state.status.update = SliceStatus.LOADING
        })
        builder.addCase(
            updateIfcEntitiesProgress.fulfilled,
            (state, action) => {
                state.status.update = SliceStatus.IDLE
                state.ifcEntities = state.ifcEntities.map((entity) => {
                    const newEntity = action.payload.data.data.find(
                        (updatedEntity: any) => updatedEntity.id === entity.id,
                    )
                    if (newEntity) {
                        return newEntity
                    }
                    return entity
                })
            },
        )
        builder.addCase(updateIfcEntitiesProgress.rejected, (state, action) => {
            state.status.update = SliceStatus.FAILED
            state.errors = (action.payload as any).data
        })
        builder.addCase(getIfcModelFiles.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(getIfcModelFiles.fulfilled, (state, action) => {
            state.status.read = SliceStatus.IDLE
            state.ifcModelFiles = action.payload.data.data
        })
        builder.addCase(getIfcModelFiles.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.ifcModelFiles = []
        })
        builder.addCase(getIfcFileNotStartedEntities.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(
            getIfcFileNotStartedEntities.fulfilled,
            (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.selectedEntities = action.payload.data.data
            },
        )
        builder.addCase(getIfcFileNotStartedEntities.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.selectedEntities = []
        })
        builder.addCase(getIfcFileInProgressEntities.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(
            getIfcFileInProgressEntities.fulfilled,
            (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.selectedEntities = action.payload.data.data
            },
        )
        builder.addCase(getIfcFileInProgressEntities.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.selectedEntities = []
        })
        builder.addCase(getIfcFileCompletedEntities.pending, (state) => {
            state.status.read = SliceStatus.LOADING
        })
        builder.addCase(
            getIfcFileCompletedEntities.fulfilled,
            (state, action) => {
                state.status.read = SliceStatus.IDLE
                state.selectedEntities = action.payload.data.data
            },
        )
        builder.addCase(getIfcFileCompletedEntities.rejected, (state) => {
            state.status.read = SliceStatus.FAILED
            state.selectedEntities = []
        })
    },
})

const selectRawSelection = (state: RootState) => state.ifcViewer.selection
export const selectSelection = createSelector(
    [selectRawSelection],
    (selection) => {
        const result: FragmentIdMap = {}
        for (const key in selection) {
            result[key] = new Set(selection[key])
        }
        return result
    },
)

const selectRawIfcFiles = (state: RootState) => state.ifcViewer.ifcFiles
export const selectIfcFiles = createSelector([selectRawIfcFiles], (ifcFiles) =>
    ifcFiles.map((file) => new ProjectFile(file)),
)

const selectRawIfcModels = (state: RootState) => state.ifcViewer.newIfcModels
export const selectIfcModels = createSelector(
    [selectRawIfcModels],
    (ifcModels) => ifcModels.map((ifcModel) => new IfcModel(ifcModel)),
)

const selectRawIfcEntities = (state: RootState) => state.ifcViewer.ifcEntities
export const selectIfcEntities = createSelector(
    [selectRawIfcEntities],
    (ifcEntities) => ifcEntities.map((ifcEntity) => new IfcEntity(ifcEntity)),
)

const selectRawIfcModelFiles = (state: RootState) =>
    state.ifcViewer.ifcModelFiles
export const selectIfcModelFiles = createSelector(
    [selectRawIfcModelFiles],
    (ifcModelFiles) => ifcModelFiles.map((file) => new ProjectFile(file)),
)

const selectSelectedEntitiesRaw = (state: RootState) =>
    state.ifcViewer.selectedEntities
export const selectSelectedEntities = createSelector(
    [selectSelectedEntitiesRaw],
    (selectedEntities) =>
        selectedEntities.map((selectedEntity) => new IfcEntity(selectedEntity)),
)

export const { setSelection, setIfcUploadProgress, setIsLoading, clearErrors } =
    ifcViewerSlice.actions
export default ifcViewerSlice.reducer
