import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

interface FileInputProps {
    label: string
    name: string
    value: File | File[] | null
    setState: (file: File | File[] | null) => void
    image?: boolean
    className?: string
    multiple?: boolean
    accept?: string
}

export const FileInput: React.FC<FileInputProps> = ({
    label,
    name,
    setState,
    className,
    value,
    image = false,
    multiple = false,
    accept,
}) => {
    const { t } = useTranslation()
    const inputRef = React.useRef<HTMLInputElement>(null)
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (e.target.files) {
            setState(multiple ? Array.from(e.target.files) : e.target.files[0])
        }
    }
    return (
        <>
            <div className={className}>
                <p className="font-bold mr-4">{label}: </p>
                {(!value || multiple) && (
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            inputRef.current?.click()
                        }}
                        className="bg-primary-300 text-secondary-100 py-2 px-5"
                    >
                        <FontAwesomeIcon icon={"upload"} className="mr-2" />
                        {t("select_file")}
                    </button>
                )}
                {value && image && !multiple && (
                    <div className="relative w-fit">
                        <FontAwesomeIcon
                            icon={"times"}
                            className="bg-primary-300 rounded-full p-1 text-sm text-secondary-100 absolute -top-1 -right-1 cursor-pointer z-10"
                            onClick={() => setState(null)}
                        ></FontAwesomeIcon>
                        <img
                            className="w-[100px] h-[100px] object-cover border-4 border-primary-300 rounded-lg"
                            alt="pic"
                            src={URL.createObjectURL(value as File)}
                        ></img>
                    </div>
                )}
                {value && image && multiple && (
                    <div className="flex flex-wrap gap-2">
                        {(value as File[]).map((file, index) => (
                            <div key={index} className="relative w-fit">
                                <FontAwesomeIcon
                                    icon={"times"}
                                    className="bg-primary-300 rounded-full p-1 text-sm text-secondary-100 absolute -top-1 -right-1 cursor-pointer z-10"
                                    onClick={() => {
                                        const newValue = (
                                            value as File[]
                                        ).filter((f, i) => i !== index)
                                        setState(
                                            newValue.length > 0
                                                ? newValue
                                                : null,
                                        )
                                    }}
                                ></FontAwesomeIcon>
                                <img
                                    className="w-[100px] h-[100px] object-cover border-4 border-primary-300 rounded-lg"
                                    alt="pic"
                                    src={URL.createObjectURL(file)}
                                ></img>
                            </div>
                        ))}
                    </div>
                )}
                {value && !image && !multiple && (
                    <div className="text-ellipsis w-1/2 flex gap-2 items-center">
                        <p>{(value as File).name}</p>
                        <FontAwesomeIcon
                            icon={"times"}
                            className="cursor-pointer"
                            onClick={() => setState(null)}
                        />
                    </div>
                )}
                {value && !image && multiple && (
                    <div className="flex flex-wrap gap-2">
                        {(value as File[]).map((file, index) => (
                            <div
                                key={index}
                                className="text-ellipsis w-1/2 flex gap-2 items-center"
                            >
                                <p>{file.name}</p>
                                <FontAwesomeIcon
                                    icon={"times"}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        const newValue = (
                                            value as File[]
                                        ).filter((f, i) => i !== index)
                                        setState(
                                            newValue.length > 0
                                                ? newValue
                                                : null,
                                        )
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
                <input
                    ref={inputRef}
                    name={name}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    multiple={multiple}
                    accept={accept}
                />
            </div>
        </>
    )
}
